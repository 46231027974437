<template>
  <div>
    <lkps-table
      :api-endpoint="apiEndpoint"
      :headers="headers"
      :form="form"
      single-export="Dapat Ditinjau"
    />
  </div>
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_jmbta',
      headers: [
        [{
          label: 'Nama Dosen Pembimbing Magang Kependidikan',
          dataKey: 'data_master_dosen.full_name',
          order: 1,
          attributes: { rowspan: 3 },
        }, {
          label: 'Banyaknya Mahasiswa Bimbingan',
          attributes: { colspan: 8 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Rata-Rata Jumlah Mahasiswa Bimbingan',
          dataKey: 'rata_rata_jumlah_mahasiswa_bimbingan',
          order: 10,
          attributes: { rowspan: 3 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Rata-Rata Banyaknya Pertemuan',
          dataKey: 'rata_rata_banyaknya_pertemuan',
          order: 11,
          attributes: { rowspan: 3 },
          dataAttributes: { class: 'text-center' },
        }],
        [{
          label: 'Di PS Sendiri',
          attributes: { colspan: 4 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Di PS Lain',
          attributes: { colspan: 4 },
          dataAttributes: { class: 'text-center' },
        }],
        [{
          label: 'TS-2',
          dataKey: 's_ts_2',
          dataAttributes: { class: 'text-center' },
          order: 2,
        }, {
          label: 'TS-1',
          dataKey: 's_ts_1',
          dataAttributes: { class: 'text-center' },
          order: 3,
        }, {
          label: 'TS',
          dataKey: 's_ts',
          dataAttributes: { class: 'text-center' },
          order: 4,
        }, {
          label: 'Rata-rata',
          dataKey: 's_rata',
          dataAttributes: { class: 'text-center' },
          order: 5,
        }, {
          label: 'TS-2',
          dataKey: 'l_ts_2',
          dataAttributes: { class: 'text-center' },
          order: 6,
        }, {
          label: 'TS-1',
          dataKey: 'l_ts_1',
          dataAttributes: { class: 'text-center' },
          order: 7,
        }, {
          label: 'TS',
          dataKey: 'l_ts',
          dataAttributes: { class: 'text-center' },
          order: 8,
        }, {
          label: 'Rata-rata',
          dataKey: 'l_rata',
          dataAttributes: { class: 'text-center' },
          order: 9,
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Dosen',
          name: 'data_master_dosen_id',
          async dataKey(data, input) {
            const response = await useJwt.http.get(`data_master_dosen/${data.data_master_dosen_id}?state=all`)
            if (response.data.data_master_dosens) {
              const { id: value, full_name: label } = response.data.data_master_dosens
              input.options = [{ label, value }]
              return value
            }
            return 0
          },
          type: 'select',
          options: [],
          placeholder: 'Cari nama dosen',
          rules: 'required',
          async fetchOptions(search, loading) {
            if (search.length >= 3) {
              loading(true)
              const response = await useJwt.http.get('data_master_dosen', { params: { full_name: search } })
              const fetchedOptions = response.data.data_master_dosens.map(dosen => ({ label: dosen.full_name, value: dosen.id }))
              loading(false)
              return fetchedOptions
            }
          },
          customSelectedValue(data) {
            return data.value
          },
        }, {
          label: 'TS-2 (PS Sendiri)',
          name: 's_ts_2',
          dataKey: 's_ts_2',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'TS-1(PS Sendiri)',
          name: 's_ts_1',
          dataKey: 's_ts_1',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'TS (PS Sendiri)',
          name: 's_ts',
          dataKey: 's_ts',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'TS-2 (PS Lain)',
          name: 'l_ts_2',
          dataKey: 'l_ts_2',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'TS-1(PS Lain)',
          name: 'l_ts_1',
          dataKey: 'l_ts_1',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'TS (PS Lain)',
          name: 'l_ts',
          dataKey: 'l_ts',
          type: 'textarea',
          
          defaultValue: 0,
        }, {
          label: 'Rata-Rata Banyaknya Pertemuan',
          name: 'rata_rata_banyaknya_pertemuan',
          dataKey: 'rata_rata_banyaknya_pertemuan',
          type: 'textarea',
          
          defaultValue: 0,
        }]
      },
    }
  },
}
</script>
